/* Defining some constants that we can reference
 * Visit https://wcsecure.weblink.com.au/Clients/silex/weblink.aspx
 * for a complete-overview of what the API provides.
 */

const currentYear = new Date().getFullYear();

module.exports = {
  ASXQUOTE: "https://wcsecure.weblink.com.au/Clients/silex/pricejson.aspx",
  OTCQUOTE: "https://wcsecure.weblink.com.au/Clients/silex//OTC/pricejson.aspx",
  ANNOUNCEMENTS: `https://clients3.weblink.com.au/Clients/silex/HeadlineJson.aspx?year=2010&toYear=${currentYear}`,
  CATEGORY: {
    all: "0",
    annualHalfYearly: "1",
    quarterly: "2",
    agm: "3",
    presentations: "4",
    financialReports: "8",
  },
};
