import React from "react";
import { StaticQuery, graphql } from "gatsby";
import GatsbyLink from "../GatsbyLink";
import { SocialLinks } from "../SocialLinks";
import { Logo } from "../Logo";
import "./Footer.scss";
import { decodeEntities } from "../../utils/helpers";

const Footer = ({
  data: {
    footerNavigation,
    legalNavigation,
    siteSettings,
    wordpressWpSettings,
  },
}) => {
  const { navigationInFooter, contactDetailsInFooter } = siteSettings.options;

  const { items: legalNav } = legalNavigation;
  const { items: footerNav } = footerNavigation;
  const officeLocation = siteSettings.options.locations[0];
  const poBox = siteSettings.options.locations[1];
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="inner">
        <div className="wrap primary-navigation">
          <div className="logo-column">
            <Logo />
            {contactDetailsInFooter &&
              Object.keys(siteSettings.options.locations).length > 0 &&
              siteSettings.options.locations?.map((location, index) => {
                return (
                  <a
                    href={`mailto:${decodeEntities(location?.email)}`}
                    key={index}
                    className="contact-link"
                  >
                    {decodeEntities(location?.email)}
                  </a>
                );
              })}
            {contactDetailsInFooter &&
              Object.keys(siteSettings.options.locations).length > 0 &&
              siteSettings.options.locations?.map((location, index) => {
                return (
                  <a
                    href={`tel:${location?.phone}`}
                    key={index}
                    className="contact-link"
                  >
                    {location?.phone}
                  </a>
                );
              })}
          </div>
          {contactDetailsInFooter && (
            <>
              {/* Standard address formatting */}
              <div className="address1-column">
                <p className="location-header">
                  {officeLocation?.locationName}
                </p>
                <address>
                  <span className="location-line">
                    {officeLocation?.addressStreet}
                  </span>
                  {officeLocation?.addressStreetOptional && (
                    <span className="location-line">
                      {officeLocation?.addressStreetOptional}
                    </span>
                  )}

                  <span className="location-line">
                    {officeLocation?.addressCity && officeLocation?.addressCity}
                    <br />
                    {officeLocation?.addressRegion &&
                      officeLocation?.addressRegion}{" "}
                    {officeLocation?.addressPostCode &&
                      officeLocation?.addressPostCode}
                  </span>
                  <span className="location-line"></span>
                  <span className="location-line">Australia</span>
                </address>
              </div>

              {/* PO Box formatting */}
              <div className="address2-column">
                <p className="location-header">{poBox?.locationName}</p>
                <address>
                  <span className="location-line">{poBox?.addressStreet}</span>
                  <span className="location-line">
                    {poBox?.addressCity && poBox?.addressCity}{" "}
                    {poBox?.addressRegion && poBox?.addressRegion}{" "}
                    {poBox?.addressPostCode && poBox?.addressPostCode}
                    <br />
                    Australia
                  </span>
                </address>
              </div>
            </>
          )}
          <div className="footer-navigation">
            {navigationInFooter && (
              <ul>
                {Object.keys(footerNav).length > 0 &&
                  footerNav?.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={`footer-navigation__item ${
                          item.classes !== ""
                            ? `footer-navigation__item--${item.classes}`
                            : ""
                        }`}
                      >
                        <GatsbyLink
                          to={item.url}
                          aria-label={item.title}
                          decode={true}
                        >
                          {item.title}
                        </GatsbyLink>
                      </li>
                    );
                  })}
              </ul>
            )}
          </div>
        </div>
        <div className="wrap secondary-navigation">
          {siteSettings.options.socialLinksFooter && <SocialLinks />}
          <div className="secondary-navigation-lower">
            {legalNav && (
              <ul className="legal-navigation">
                {Object.keys(legalNav).length > 0 &&
                  legalNav?.map((item, index) => {
                    return (
                      <li key={index} className="legal-navigation__item">
                        <GatsbyLink to={item.url} decode={true}>
                          {item.title}
                        </GatsbyLink>
                      </li>
                    );
                  })}
              </ul>
            )}
            <span className="copyright">
              ©{currentYear} {wordpressWpSettings.title}, All rights reserved
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        legalNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "legal-navigation" }
        ) {
          items {
            title
            url
            classes
            slug: object_slug
          }
        }
        footerNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "footer-navigation" }
        ) {
          items {
            title
            url
            classes
            slug: object_slug
            children: wordpress_children {
              title
              url
              classes
              slug: object_slug
            }
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            locations {
              email
              phone
              addressCity
              addressPostCode
              addressRegion
              addressStreet
              addressStreetOptional
              locationName
            }
            socialLinksFooter
            navigationInFooter
            contactDetailsInFooter
            privacyCookiePolicyNotice
            privacyCookiePolicyNoticeText
          }
        }
        wordpressWpSettings {
          title
        }
      }
    `}
    render={(data) => <Footer data={data} {...props} />}
  />
);
