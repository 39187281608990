import React, { useState, useEffect } from "react";
import { getASXAnnouncements } from "../utils/asx";
/**
 * Hook which calls our announcement data
 * @param {string} year Year for the announcements
 * @param {string} getAllYear Retrieves every document from the year
 * @param {string} pageNumber Paginated API result parameter
 * @param {string} numPerPages Articles to return per page
 * @param {("all"|"annualHalfYearly"|"quarterly"|"agm"|"presentations")} asxCategory ENUM for which category we wish to filter/query by, e.g. "announcements"
 * @param {function} setDataReady
 * @returns {object}
 */
export function useAnnouncementData(
  year,
  getAllYear = "false",
  pageNumber,
  numPerPages,
  asxCategory,
  setDataReady = () => {}
) {
  const [announcementData, setAnnouncementData] = useState(null);

  useEffect(() => {
    async function retrieveAnnouncementData() {
      try {
        let data = await getASXAnnouncements(
          year,
          getAllYear,
          pageNumber,
          numPerPages,
          asxCategory
        );
        if (data) {
          setAnnouncementData(data);
          setDataReady(true);
        }
      } catch (error) {
        setDataReady(false);
      }
    }
    retrieveAnnouncementData();
  }, [pageNumber]);
  return announcementData;
}
