import React, { useState } from "react";
import GatsbyLink from "../GatsbyLink";
import "./HeaderNav.scss";

const isClient = typeof window !== "undefined";

const NavItem = ({
  item: { object_id, title, url, children, target, classes },
  baseUrl,
  openNavKey,
  toggleSubNav,
  resetHoverClear,
}) => {
  const isOpen = openNavKey === object_id;

  return children ? (
    // items with children
    <span key={object_id} className="children">
      <GatsbyLink
        to={url}
        onClick={(event) => resetHoverClear(event, true)}
        target={target ? target : null}
        // activeClassName="active"
        aria-label={title}
        decode={true}
        className={classes}
      >
        {title}
      </GatsbyLink>
      {children && children[0].url !== "#gatsby" && (
        <>
          <NavChildren
            baseUrl={baseUrl}
            childNav={children}
            isOpen={isOpen}
            resetHoverClear={resetHoverClear}
          />
          <button
            type="button"
            className={`expand ${isOpen ? "open" : "closed"}`}
            onClick={(event) => toggleSubNav(event, object_id)}
            aria-label="Open Menu"
          />
        </>
      )}
    </span>
  ) : (
    // items with no children
    <span key={object_id} className="no-children">
      <GatsbyLink
        to={url}
        onClick={(event) => resetHoverClear(event, true)}
        target={target ? target : null}
        // activeClassName="active"
        aria-label={title}
        decode={true}
        className={classes}
      >
        {title}
      </GatsbyLink>
    </span>
  );
};

const NavChildren = ({ childNav, isOpen = null, resetHoverClear }) => {
  return (
    <ul className={`children ${isOpen ? "open" : "close"}`}>
      {childNav.map((child, index) => {
        const { title, url, target } = child;
        return (
          <li key={index}>
            <GatsbyLink
              to={url}
              onClick={(event) => resetHoverClear(event, true)}
              target={target ? target : null}
              // activeClassName="active"
              aria-label={title}
              decode={true}
            >
              {title}
            </GatsbyLink>
          </li>
        );
      })}
    </ul>
  );
};

export const HeaderNav = ({
  navItems,
  baseUrl,
  className,
  hoverClear,
  resetHoverClear,
}) => {
  const [openNavKey, setOpenNavKey] = useState(null);

  const toggleSubNav = (event, newNavKey) => {
    event.preventDefault();
    if (newNavKey === openNavKey) return setOpenNavKey(null);
    return setOpenNavKey(newNavKey);
  };

  return (
    <ul className={className}>
      {navItems &&
        navItems.map(
          (col, index) =>
            index < 10 &&
            col.url !== "#gatsby" && (
              <li key={index}>
                <NavItem
                  key={col.url}
                  item={col}
                  baseUrl={baseUrl}
                  toggleSubNav={toggleSubNav}
                  openNavKey={openNavKey}
                  resetHoverClear={resetHoverClear}
                  hoverClear={hoverClear}
                />
              </li>
            )
        )}
    </ul>
  );
};
