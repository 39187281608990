import React from "react";
import GatsbyLink from "../GatsbyLink";
import "./Logo.scss";

export const Logo = () => {
  return (
    <GatsbyLink to="/" className="logo">
      <svg
        width="218px"
        height="72px"
        viewBox="0 0 218 72"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className="logo-link"
      >
        <title>Logo/Rev</title>
        <defs>
          <polygon
            id="path-1"
            points="1.42108547e-14 87.326 218.027 87.326 218.027 0 1.42108547e-14 0"
          ></polygon>
        </defs>
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Header" transform="translate(-136.000000, -44.000000)">
            <g id="Group" transform="translate(136.000000, 44.000000)">
              <path
                d="M40.4731,4.5502 L35.9221,0.0002 L2.9771,32.9442 C1.9861,33.9702 0.9911,34.9942 0.0001,36.0192 L18.4961,54.5172 C29.9721,42.0102 27.1161,15.1742 40.4731,4.5502"
                id="Fill-1"
                fill="#B9D137"
              ></path>
              <path
                d="M53.7729,17.8504 L42.2779,6.3554 C37.1999,11.4114 36.4849,20.8274 34.1899,28.6634 C32.3949,36.8694 30.4109,44.2004 25.6409,50.6834 C24.1969,52.6814 22.0339,54.7164 20.4649,56.4844 L31.7609,67.7824 C42.2819,55.9924 40.6479,32.0484 51.1119,20.2014 C52.2679,18.9574 53.0329,18.3484 53.7729,17.8504"
                id="Fill-3"
                fill="#B9D137"
              ></path>
              <path
                d="M71.8931,35.972 L55.4591,19.537 C46.9671,29.891 48.0831,50.812 40.1951,62.226 C38.9161,64.412 36.8231,66.812 33.6291,69.652 L35.9221,71.941 L71.8931,35.972 Z"
                id="Fill-5"
                fill="#B9D137"
              ></path>
              <path
                d="M99.4785,44.4872 C99.2705,54.8162 106.7195,57.9762 115.8545,57.9762 C121.1245,57.9742 129.1345,55.0902 129.1345,47.7812 C129.1325,42.0912 123.4415,39.8432 118.8025,38.7202 L107.6285,35.9822 C101.7265,34.5072 96.3135,31.6982 96.3135,23.8972 C96.3115,18.9782 99.4715,9.7732 113.5965,9.77019944 C123.5035,9.7682 132.2185,15.1072 132.1525,25.3652 L127.3725,25.3672 C127.0895,17.7072 120.7655,13.8432 113.6675,13.8432 C107.1325,13.8452 101.0895,16.3782 101.0915,23.8252 C101.0925,28.5332 104.6055,30.5022 108.6825,31.4842 L120.9095,34.5032 C128.0075,36.4002 133.912502,39.4902 133.912502,47.7132 C133.9145,51.1562 132.5095,62.0472 114.7315,62.0502035 C102.8545,62.0552 94.1405,56.7162 94.7005,44.4872 L99.4785,44.4872 Z"
                id="Fill-7"
                fill="#FFFFFE"
              ></path>
              <path
                d="M137.2051,24.7323 L141.6311,24.7323 L141.6351,43.3723 L141.6391,60.9923 L137.2131,60.9923 L137.2051,24.7323 Z M137.2031,10.8193 L141.6311,10.8173 L141.6311,17.9163 L137.2051,17.9163 L137.2031,10.8193 Z"
                id="Fill-9"
                fill="#FFFFFE"
              ></path>
              <polygon
                id="Fill-11"
                fill="#FFFFFE"
                points="146.6875 10.8172 151.1155 10.8172 151.1175 25.2322 151.1245 60.9882 146.6975 60.9882"
              ></polygon>
              <path
                d="M182.1777,40.3236 C181.9647,33.6476 177.8187,27.3946 170.6507,27.3946 C163.4137,27.3976 159.4077,33.7216 158.7067,40.3276 L182.1777,40.3236 Z M158.7087,44.0526 C158.7797,50.5176 162.1547,58.3176 170.6577,58.3146 C177.1227,58.3146 180.6347,54.5196 182.0387,49.0366 L186.4647,49.0366 C184.5707,57.2596 179.7947,62.0376 170.6577,62.0396008 C159.1327,62.0426 154.2827,53.1896 154.2797,42.8586 C154.2797,33.3016 159.1267,23.6736 170.6507,23.6725988 C182.3147,23.6686 186.9547,33.8576 186.6077,44.0476 L158.7087,44.0526 Z"
                id="Fill-13"
                fill="#FFFFFE"
              ></path>
              <polygon
                id="Fill-15"
                fill="#FFFFFE"
                points="198.418 42.1459 185.414 24.7239 190.963 24.7239 201.299 38.4919 211.414 24.7179 216.965 24.7149 203.898 42.0739 218.027 60.9749 212.406 60.9779 201.018 45.7299 189.852 60.9799 184.367 60.9799"
              ></polygon>
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <g id="Clip-35"></g>
            </g>
          </g>
        </g>
      </svg>
    </GatsbyLink>
  );
};
