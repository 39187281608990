import React, { useState, useEffect, useCallback } from "react";
import { StaticQuery, graphql } from "gatsby";
import { HeaderNav } from "../HeaderNav";
import { Logo } from "../Logo";
import Headroom from "react-headroom";
import { SocialLinks } from "../SocialLinks";
import NavAsxData from "../ASX/NavAsxData";
import "./Header.scss";
import GatsbyLink from "../GatsbyLink";
import AnnouncementBar from "./AnnouncementBar";
import { useIsClient, useWindowSize } from "../../hooks";

const HeaderComponent = ({
  data: {
    abovePrimaryNavigation: {
      items: { aboveNavItems },
    },
    primaryNavigation: { items: navItems },
    siteSettings: { options: siteOptions },
    metaData,
    wordpressWpSettings: { baseUrl },
  },
}) => {
  const isClient = useIsClient();

  const {
    showAnnouncementBar = false,
    announcementBarText = "",
    announcementBarLink = "",
  } = siteOptions || {};

  // component states
  const [navActive, setNavActive] = useState(false);
  const [hoverClear, setHoverClear] = useState(false);

  // toggles the navigation open and close
  const toggleNav = useCallback(
    (event) => {
      if (event) event.preventDefault();
      if (!isClient && !navActive) {
        document.body.classList.add("nav-open");
      } else if (!isClient && navActive) {
        document.body.classList.remove("nav-open");
      }
      setNavActive((previousState) => !previousState);
    },
    [navActive, isClient]
  );

  // resize logic
  const windowOnResize = useCallback(
    (size) => {
      if (size.width > 1080) {
        let childrenDomElements = document.querySelectorAll(".children");
        for (let i = 0; i < childrenDomElements.length; i++) {
          if (childrenDomElements[i].classList.contains("open")) {
            childrenDomElements[i].classList.remove("open");
          }
        }
        if (document.body.classList.contains("nav-open")) {
          toggleNav();
        }
      }
    },
    [toggleNav]
  );

  const size = useWindowSize();

  useEffect(() => {
    if (isClient) {
      window.addEventListener("resize", windowOnResize(size));
      return () => {
        window.removeEventListener("resize", windowOnResize(size));
      };
    }
  }, [isClient, size, windowOnResize]);

  //
  const resetHoverClear = () => {
    setHoverClear(true);
    setNavActive(false);
    if (isClient) {
      document.body.classList.remove("nav-open");
    }
    setTimeout(() => {
      setHoverClear(false);
    }, 1000);
  };

  return (
    <Headroom disableInlineStyles pinStart={50}>
      <header className={siteOptions.invertHeaderColours ? "invert" : ""}>
        {siteOptions?.navigationAbovePrimaryMenu && (
          <div className="above-header">
            <div className="wrap">
              <NavAsxData />
              <AnnouncementBar
                showAnnouncementBar={showAnnouncementBar}
                announcementBarLink={announcementBarLink}
                announcementBarText={announcementBarText}
              />
            </div>
          </div>
        )}
        <div className="header-main">
          <div className="wrap">
            <div className="inner">
              <Logo />
              <div className="nav">
                <nav className={`nav-inner${navActive ? " active" : ""}`}>
                  <HeaderNav
                    active={navActive}
                    className="navigation"
                    navItems={navItems}
                    resetHoverClear={resetHoverClear}
                    hoverClear={hoverClear}
                    baseUrl={baseUrl}
                    toggleNav={toggleNav}
                  />
                </nav>
                <div className="controls">
                  <button
                    type="button"
                    className={`control-nav icon-hamburger ${
                      navActive ? "active" : ""
                    }`}
                    onClick={toggleNav}
                    aria-label="Toggle Menu"
                  >
                    <span className={`burger ${navActive ? "active" : ""}`} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </Headroom>
  );
};

export function Header() {
  return (
    <StaticQuery
      query={graphql`
        query {
          abovePrimaryNavigation: wordpressWpApiMenusMenusItems(
            slug: { eq: "above-primary-navigation" }
          ) {
            items {
              title
              url
              classes
              object_id
              slug: object_slug
              target
              children: wordpress_children {
                title
                url
                classes
                object_id
                slug: object_slug
                target
              }
            }
          }
          primaryNavigation: wordpressWpApiMenusMenusItems(
            slug: { eq: "primary-navigation" }
          ) {
            items {
              title
              url
              classes
              slug: object_slug
              object_id
              target
              children: wordpress_children {
                title
                url
                classes
                object_id
                slug: object_slug
                target
              }
            }
          }
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              navigationAbovePrimaryMenu
              socialLinksHeader
              contactNumberHeader
              invertHeaderColours
              showSearchIconInMainNavigation
              locations {
                addressDisplay
                email
                phone
              }
              quote
              # quotePage
              showQuoteButtonInHeader
              showAnnouncementBar
              announcementBarText
              announcementBarLink
              # quotePage
            }
          }
          wordpressWpSettings {
            siteUrl
          }
        }
      `}
      render={(data) => <HeaderComponent data={data} />}
    />
  );
}
