import React from "react";
import PropTypes from "prop-types";
import GatsbyLink from "../../GatsbyLink";
import "./AnnouncementBar.scss";

const index = ({
  showAnnouncementBar,
  announcementBarText,
  announcementBarLink,
}) => {
  return showAnnouncementBar ? (
    announcementBarLink ? (
      <div className="alert-bar">
        <GatsbyLink to={announcementBarLink}>
          <span>{announcementBarText}</span>
        </GatsbyLink>
      </div>
    ) : (
      <div className="alert-bar">
        <span>{announcementBarText}</span>
      </div>
    )
  ) : null;
};

index.propTypes = {
  className: PropTypes.string,
  showAnnouncementBar: PropTypes.bool,
  announcementBarText: PropTypes.string,
  announcementBarLink: PropTypes.string,
};

export default index;
