import { ANNOUNCEMENTS, CATEGORY } from "./asx-config";

/**
 * A list of helper functions for returning API data from ASX routes
 * provided by Weblink API.
 * Weblink API returns data via XML.
 */

/**
 * Fetches 20 minute time retarded data with the specified QUOTE url from the asx-config
 * @returns asxData from weblink API
 */
export const getASXData = async (url) => {
  try {
    const response = await fetch(url);
    const data = await response.text();
    const asxData = JSON.parse(data);
    return asxData;
  } catch (error) {
    console.error(error);
  }
};

/* For reference:
 * https://wcsecure.weblink.com.au/Clients/maca/HeadlineJsonP.asmx/getHeadlines?numberHdPerPage=1000&pageNumber=1&year=2019&getAllHDFromThisyear=true&category=0
 * NOTE: set allYear to be 'false' to prevent from retrieving input year and years before.
 */

/**
 *
 * @param {string} year Year for the announcements
 * @param {string} getAllYear Retrieves every document from the year
 * @param {string} pageNumber Paginated API result parameter
 * @param {string} numPerPages Articles to return per page
 * @param {string} asxCategory ENUM for which category we wish to filter/query by
 * @returns {object} Announcement data from Weblinks API
 */
export const getASXAnnouncements = async (
  year = "2020",
  getAllYear = "false",
  pageNumber = "1",
  numPerPages = "500",
  asxCategory = "all"
) => {
  /* Retrieve parameters needed for the url and
   * Build the URL using the data.
   * Use our CATEGORY object to map the correct property with the right integer value needed in the api
   */
  const urlCategory = CATEGORY[asxCategory];

  const headlineUrl = `${ANNOUNCEMENTS}&numberHdPerPage=${numPerPages}&pageNumber=${pageNumber}&getAllHDFromThisyear=${getAllYear}&category=${urlCategory}`;
  try {
    const response = await fetch(headlineUrl);
    const data = await response.text();
    // parse our data into a more readable into JavaScript's JSON Format.
    const parsedData = JSON.parse(data);
    const allData = parsedData?.WebLinkHeadlineData;
    const { totalHeadlines, totalPages, headlines } = allData;
    // return an object
    const announcementData = {
      totalHeadlines,
      totalPages,
      headlines,
    };
    return announcementData || {};
  } catch (error) {
    console.error(error);
  }
};
