import React, { useEffect, useState } from "react";
import { usePersistedState } from "../../utils/helpers";
import { getASXData } from "../../utils/asx";
import "./NavAsxData.scss";
import { ASXQUOTE, OTCQUOTE } from "../../utils/asx-config.js";

/**
 * A component which returns a div containing the short code ASX data
 */

/**
 * We can pass a time for expiry for fetching the data via the
 * usePersistedState hook
 */
const ASX_EXPIRY_TIME = 1200000; // 20 minutes

const NavAsxData = (props) => {
  /**
   * Setting dummy data will initialise the key in local storage
   */
  const [fetchError, setFetchError] = useState(false);
  const [currentAsxData, setCurrentAsxData] = usePersistedState(
    "asx",
    {
      quote: [
        {
          movement: 0,
          symbol: "ASX",
          lastprice: "-.--",
        },
      ],
    },
    ASX_EXPIRY_TIME
  );
  const [currentOtcData, setCurrentOtcData] = usePersistedState(
    "otc",
    {
      quote: [
        {
          movement: 0,
          symbol: "OTCQX",
          lastprice: "-.--",
        },
      ],
    },
    ASX_EXPIRY_TIME
  );

  useEffect(() => {
    let isFetched = false;

    const fetchASXData = async () => {
      if (!isFetched) {
        let resultAsx = await getASXData(ASXQUOTE);
        let resultOtc = await getASXData(OTCQUOTE);
        setCurrentAsxData(resultAsx);
        setCurrentOtcData(resultOtc);
        // Anticipating our data is returning with quote as part of it
        if (!resultAsx.quote) {
          setFetchError(true);
        } else {
          setFetchError(false);
        }
      }
    };

    /**
     * If currentAsxData in state is equal to the dummyData
     * then we want to fetch for actual ASX data
     */
    currentAsxData?.quote[0] ? fetchASXData() : null;

    // useEffect cleanup function if component is mounted
    return () => {
      isFetched = true;
    };
  }, [ASX_EXPIRY_TIME]);

  if (fetchError) {
    console.error(
      "There was an error retrieving the fetch data in NavAsxData."
    );
    return null;
  }

  const { movement: asxMovement, symbol: asxSymbol, lastprice: asxLastprice } =
    currentAsxData &&
    currentAsxData?.quote?.length > 0 &&
    currentAsxData.quote[0];

  const { movement: otcMovement, symbol: otcSymbol, lastprice: otcLastprice } =
    currentOtcData &&
    currentOtcData?.quote?.length > 0 &&
    currentOtcData.quote[0];
  /**
   * If the movement is positive, we should display a green up-arrow.
   * Conversely, a negative movement should display a red down-arrow.
   * This is achieved via CSS class toggling.
   */
  const asxPositiveMovement = asxMovement >= 0.0 ? true : false;
  const otcPositiveMovement = asxMovement >= 0.0 ? true : false;

  return currentAsxData && currentOtcData ? (
    <div className="asx-data">
      <div className="data">
        <span>{otcSymbol}</span>
        <span>${parseFloat(otcLastprice).toFixed(2)}</span>
        <div
          className={`movement-symbol symbol-${
            otcPositiveMovement ? `positive` : `negative`
          }`}
        ></div>
      </div>
      <div className="data">
        <span>{asxSymbol}</span>
        <span>${parseFloat(asxLastprice).toFixed(2)}</span>
        <div
          className={`movement-symbol symbol-${
            asxPositiveMovement ? `positive` : `negative`
          }`}
        ></div>
      </div>
    </div>
  ) : (
    <div className="asx-data">
      <div className="loader-wrapper">
        <span className="loader"></span>
      </div>
    </div>
  );
};

export default NavAsxData;
